import React, { useContext, useEffect, useRef, useState } from 'react';
import PageLayout from 'layout/page/Page.layout';
import { Container } from 'layout/page/Page.layout.styled';
import { QuestionnaireMainWrapper } from 'pages/questionnaire/Questionnaire.styled';
import { SwiperSlide } from 'swiper/react';
import Slider from 'components/slider/Slider';
import { Swiper as SwiperClass } from 'swiper/types';
import QuestionnaireHome from 'pages/questionnaire/slides/home/QuestionnaireHome';
import QuestionnaireProfileForm from 'pages/questionnaire/slides/profile/QuestionnaireProfileForm';
import useAPIQuestionnaire, { IQuestionnaireData } from 'hooks/api/useAPIQuestionnaire';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import QuestionnaireMain from 'pages/questionnaire/slides/questionnaire/QuestionnaireMain';
import QuestionnaireEnd from 'pages/questionnaire/slides/end/QuestionnaireEnd';
import useUpdateChecker from 'hooks/useUpdateChecker';
import useAPIAuth from 'hooks/api/useAPIAuth';
import { Questionnaire as QuestionnaireType } from 'types/app';
import { PRIMARY_QUESTIONNAIRE_NB, TOTAL_QUESTIONNAIRE } from 'constants/global';
import { ROUTES } from 'constants/routes';
import { setAnsweredQuestionnaireCookie } from 'helpers/utils';

const Questionnaire: React.FC = () => {
  const { checkUserDetails } = useAPIAuth();
  useUpdateChecker();
  const { user } = useContext(UserContext) as UserContextProps;
  const { loadQuestionnaireData, loadAdditionalQuestionnaire } = useAPIQuestionnaire();
  const swiperInstance = useRef<SwiperClass>();
  const [slidesContents, updateSlidesContents] = useState<Array<React.ReactElement>>([]);
  const [topicNumbers, setTopicNumbers] = useState<number>(0);
  const currentChapterSectionNumber = useRef<number>(0);

  function insertTransitionsAtBeginning(originalArray: QuestionnaireType.IQuestion[]) {
    let notPassedChapterSectionIds: number[] = [];
    let tmpCurrentChapterSectionNumber = 0;
    const resultArray: any[] = [];
    let tmpTopicNumbers = topicNumbers;

    let nextSectionLabel = {};

    originalArray.forEach((currentItem, index, array) => {
      if (index < array.length - 1 && currentItem.sectionId !== array[index + 1].sectionId) {
        nextSectionLabel = array[index + 1].sectionLabel;
      }

      if (index === 0 && originalArray.length > 0) {
        nextSectionLabel = array[index + 1].sectionLabel;
      }

      const transitionObject: QuestionnaireType.IQuestion = {
        id: -1,
        sectionId: -1,
        sectionLabel: nextSectionLabel,
        label: {},
        order: -1,
        responseMax: -1,
        responseMin: -1,
        response: -1,
      };

      if (index === 0 || currentItem.sectionId !== array[index - 1].sectionId) {
        resultArray.push(transitionObject);
        notPassedChapterSectionIds.push(currentItem.sectionId);
        tmpTopicNumbers += 1;
      }

      resultArray.push(currentItem);
      if (currentItem.response && notPassedChapterSectionIds.includes(currentItem.sectionId)) {
        notPassedChapterSectionIds = notPassedChapterSectionIds.filter(
          (id) => id !== currentItem.sectionId,
        );
        tmpCurrentChapterSectionNumber += 1;
      }
    });
    currentChapterSectionNumber.current = tmpCurrentChapterSectionNumber;
    setTopicNumbers(tmpTopicNumbers);
    return resultArray;
  }

  const createSlides = async (qData: IQuestionnaireData) => {
    const slides: Array<React.ReactElement> = [];
    qData.textIntro && slides.push(<QuestionnaireHome textIntro={qData.textIntro} />);
    qData.activities &&
      slides.push(
        <QuestionnaireProfileForm
          activities={qData.activities}
          currentActivity={user?.profileInfo?.activity}
          currentBirthYear={user?.profileInfo?.birthYear}
        />,
      );

    if (qData.questionnaire && qData.questionnaire?.progression < Math.floor(PRIMARY_QUESTIONNAIRE_NB * 100 / TOTAL_QUESTIONNAIRE)) {
      const modifiedArray = insertTransitionsAtBeginning(qData.questionnaire.questions);
      slides.push(
        <QuestionnaireMain
          q={{
            ...qData.questionnaire,
            questions: [...modifiedArray],
            currentQuestionIndex:
              qData.questionnaire.currentQuestionIndex + currentChapterSectionNumber.current,
          }}
          topicNumbers={0}
        />,
      );
    } else {
      const additionalQData = await loadAdditionalQuestionnaire();
      if (additionalQData && additionalQData.questionnaire && additionalQData.questionnaire.progression < 100) {
        slides.push(
          <QuestionnaireMain
            q={{
              ...additionalQData.questionnaire,
              id: 21,
              questions: [...additionalQData.questionnaire.questions],
              currentQuestionIndex:
                additionalQData.questionnaire.currentQuestionIndex + currentChapterSectionNumber.current,
            }}
            topicNumbers={0}
            isAdditional={true}
          />,
        );
      } else {
        if (additionalQData.questionnaire && additionalQData.questionnaire.progression >= 100) {
          setAnsweredQuestionnaireCookie();
          window.location.href = ROUTES.INDEX;
        }
      }
    }

    // end
    slides.push(<QuestionnaireEnd />);
    updateSlidesContents(slides);
  };

  const loadData = async () => {
    user && await checkUserDetails(user.id, user.token);
    await createSlides(await loadQuestionnaireData());
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <PageLayout
      headerProps={{ showLogo: true, hideNotification: true, showLangSwitcher: true }}
      className="page-layout-questionnaire"
    >
      <QuestionnaireMainWrapper>
        <Container padding={0} className="app-page">
          <Slider
            onInit={(swiper: SwiperClass) => {
              swiperInstance.current = swiper;
            }}
            allowTouchMove={false}
          >
            {slidesContents.map((Compo, index) => (
              <SwiperSlide key={`compo-${index}`}>{Compo}</SwiperSlide>
            ))}
          </Slider>
        </Container>
      </QuestionnaireMainWrapper>
    </PageLayout>
  );
};

export default Questionnaire;
